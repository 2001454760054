import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select} from "@mui/material";
import MDButton from "../components/MDButton";
import {useState} from "react";
import * as PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import apiHost from "../utils/apiHost";
import Download from "downloadjs";
import {useAuth} from "../useAuth";
import {useSnackbar} from "../useSnackbar";

function PrintDialog({open, handleClose, inventoryType, inventoryIds}) {
    const [formType, setFormType] = useState(4);
    const { performLoginCheck } = useAuth();
    const {showError} = useSnackbar();

    const onCreateClicked = () => {
        console.debug(inventoryType, inventoryIds, formType);

        fetch(`${apiHost()}/api/qr/create`, {
            method: 'POST',
            credentials: apiHost().length === 0 ? 'same-origin' : 'include',
            body: JSON.stringify({
                formType: formType,
                type: inventoryType,
                ids: inventoryIds
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(function(resp) {
            if(resp.ok) {
                return resp.blob();
            } else {
                // TODO: inform user
                if(resp.status === 401) {
                    performLoginCheck(null);
                } else {
                    showError("Request failed (" + resp.status + ")");
                }
            }
        }).then(function(blob) {
            if(blob) {
                return Download(blob, "CUSTOM_NAME.pdf");
            }
        }).catch(function (reason) {
            // TODO: inform user
            console.error(reason);
        });



    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Print QR Code(s)</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{mb: 1}}>
                    Number of QR codes per page (A4):
                </DialogContentText>
                    <Select fullWidth sx={{
                        '& .MuiInputBase-input': {
                            m: 1
                        }
                    }}
                        displayEmpty
                        value={formType}
                        onChange={(e) => setFormType(e.target.value)}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                    </Select>


            </DialogContent>
            <DialogActions>
                <MDButton variant="text" color="secondary" onClick={handleClose}>Cancel</MDButton>
                <MDButton onClick={onCreateClicked} color="primary">Create</MDButton>
            </DialogActions>
        </Dialog>
    );
}

PrintDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    inventoryType: PropTypes.oneOf(["BREEDING_CAGE", "HATCHING_BOX", "HATCHLING_CONTAINER"]).isRequired,
    inventoryIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default PrintDialog;