import colors from "assets/theme/base/colors";

const { dark } = colors;

const cardHeader = {
  styleOverrides: {
    root: {
      /*backgroundColor: secondary.main,*/
      color: dark.main
    },
  },
};

export default cardHeader;
