import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import MDButton from "../components/MDButton";
import MDInput from "../components/MDInput";
import {useState} from "react";
import * as PropTypes from "prop-types";

function NewInventoryItemsDialog({open, handleClose, handleAdd, inventoryTitle}) {
    const [numberItems, setNumberItems] = useState(1);

    const onAddClicked = () => {
        handleAdd(numberItems);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add {inventoryTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{mb: 1}}>
                    Add any number of new items.
                </DialogContentText>
                <MDInput type="number" fullWidth value={numberItems} onChange={(e) => setNumberItems(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <MDButton variant="text" color="secondary" onClick={handleClose}>Cancel</MDButton>
                <MDButton onClick={onAddClicked} color="primary">Add</MDButton>
            </DialogActions>
        </Dialog>
    );
}

NewInventoryItemsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    inventoryTitle: PropTypes.string.isRequired
};

export default NewInventoryItemsDialog;