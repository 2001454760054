import colors from "assets/theme/base/colors";

const { white } = colors;


const accordion = {
  styleOverrides: {
    root: {
      /*display: "flex",
      flexDirection: "column",
      position: "relative",
      minWidth: 0,
      wordWrap: "break-word",*/
      backgroundColor: white.main,
      /*backgroundClip: "border-box",
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
      borderRadius: borderRadius.xl,
      boxShadow: md,
      overflow: "visible",
      */
    },
  },
};

export default accordion;
