import {setDarkMode, setLayout, setMiniSidenav, useMaterialUIController} from "../context";
import Sidenav from "../components/Sidenav";
import routes from "../routes";
import {useEffect, useState} from "react";
import {useAuth} from "../useAuth";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import DashboardLayout from "../components/DashboardLayout";
import Footer from "../components/Footer";
import apiHost from "../utils/apiHost";
import LoadingPage from "../pages/LoadingPage";


function ProtectedLayout() {
    const { user, setUser, loginCheck, setNotifications } = useAuth();

    const [controller, dispatch] = useMaterialUIController();
    const { pathname } = useLocation();
    const {
        miniSidenav,
        layout,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;

    let navigate = useNavigate();

    useEffect(() => {
        setLayout(dispatch, "dashboard");
    }, [dispatch, pathname]);

    useEffect(() => {
        if(user == null) {
            loginCheck().then((user) => {
                if (!user) {
                    navigate('/u/login');
                } else {
                    console.debug("setNotifications (after logincheck in layout) - " + user.notifications.length);
                    setNotifications(user.notifications);
                    delete user['notifications'];
                    setUser(user);
                    setDarkMode(dispatch, user.darkMode);
                }
            });
        }
    },
    [dispatch, loginCheck, navigate, setNotifications, setUser, user]);

    const [onMouseEnter, setOnMouseEnter] = useState(false);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    return (
            !!user ? (
            <>
                {(layout !== "vr") && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brand={(transparentSidenav && !darkMode) || whiteSidenav ? apiHost() + '/res/logo-sm' : apiHost() + '/res/logo-sm-light'}
                            brandIcon={(transparentSidenav && !darkMode) || whiteSidenav ? apiHost() + '/res/logo-icon' : apiHost() + '/res/logo-icon-light'}
                            brandName="Vileala"
                            routes={routes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                    </>
                )
                }

                <DashboardLayout>
                    <DashboardNavbar />
                    <Outlet flex={2} />
                    <Footer />
                </DashboardLayout>
            </>) : <LoadingPage />


    )
}

export default ProtectedLayout;
