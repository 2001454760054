
/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
//import Dashboard from "src/pages/Dashboard";
/*import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";
 */

// Material Dashboard 2 PRO React components
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import InboxIcon from '@mui/icons-material/Inbox';
import SettingsIcon from '@mui/icons-material/Settings';

// @mui icons
import Icon from "@mui/material/Icon";
import GridViewIcon from '@mui/icons-material/GridView';
import {Farm} from "./assets/FarmIcons";

// Images
//import profilePicture from "assets/images/team-3.jpg";

const routes = [
  { type: "title", title: "Administration", key: "title-admin", roles: ['ADMIN'] },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    roles: ['ADMIN'],
    icon: <Icon fontSize="medium">group</Icon>,
    route: "/u/users",
    noCollapse: true
  }, {
    type: "collapse",
    name: "Devices",
    key: "devices",
    roles: ['ADMIN'],
    icon: <TabletAndroidIcon fontSize="medium" />,
    collapse: [
      {
        name: "Mobiles",
        key: "mobiles",
        route: "/u/devices/mobiles",
        roles: ['ADMIN'],
      },
      {
        name: "Gateways",
        key: "gateways",
        route: "/u/devices/gateways",
        roles: ['ADMIN'],
      },
      {
        name: "Field Devices",
        key: "fieldDevices",
        route: "/u/devices/field-devices",
        roles: ['ADMIN'],
      },
      {
        name: "Proxy Devices",
        key: "proxyDevices",
        route: "/u/devices/proxy-devices",
        roles: ['ADMIN'],
      }
    ]
  }, {
    type: "collapse",
    name: "Farms",
    key: "farms",
    roles: ['ADMIN'],
    icon: <Farm fontSize="large" />,
    route: "/u/farms",
    noCollapse: true
  }, {
    type: "collapse",
    name: "Settings",
    key: "settings",
    roles: ['ADMIN'],
    icon: <SettingsIcon fontSize="large" />,
    route: "/u/settings",
    noCollapse: true
  }, {
    type: "divider", key: "divider-admin",
    roles: ['ADMIN']
  },{
    type: "title-inventory", title: "Inventory", key: "title-inventory", roles: ['ADMIN', 'MANAGER']
  },{
    type: "collapse",
    name: "Breeding Cages",
    key: "breeding-cages",
    stages: ['BREEDING'],
    roles: ['ADMIN','MANAGER'],
    icon: <HouseSidingIcon fontSize="medium" />,
    route: "/u/breeding-cages",
    noCollapse: true
  },{
    type: "collapse",
    name: "Hatching Boxes",
    key: "hatching-boxes",
    stages: ['BREEDING'],
    roles: ['ADMIN','MANAGER'],
    icon: <AllInboxIcon fontSize="medium" />,
    route: "/u/hatching-boxes",
    noCollapse: true
  },{
    type: "collapse",
    name: "Hatchling Containers",
    key: "hatchling-containers",
    stages: ['BREEDING'],
    roles: ['ADMIN','MANAGER'],
    icon: <Inventory2Icon fontSize="medium" />,
    route: "/u/hatchling-containers",
    noCollapse: true
  },{
    type: "collapse",
    name: "Crates",
    key: "crates",
    stages: ['FATTENING'],
    roles: ['ADMIN','MANAGER'],
    icon: <InboxIcon fontSize="medium" />,
    route: "/u/crates",
    noCollapse: true
  },{
    type: "divider", key: 'divider-inventory',
    roles: ['ADMIN', 'MANAGER']
  },{
    type: "collapse",
    name: "Status",
    key: "status",
    roles: ['ADMIN','MANAGER','ANALYST'],
    icon: <GridViewIcon fontSize="medium" />,
    collapse: [
      {
        name: "Overview",
        key: "overview",
        route: "/u/status/overview",
        roles: ['ADMIN','MANAGER','ANALYST'],
      },
      {
        name: "Devices",
        key: "device-overview",
        route: "/u/status/device-overview",
        roles: ['ADMIN','MANAGER','ANALYST'],
      },
      {
        name: "Gateway Tasks",
        key: "gateway-tasks",
        route: "/u/status/gateway-tasks",
        roles: ['ADMIN'],
      }
    ],
  }
];

export default routes;
