import MDBox from "../components/MDBox";
import {useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import apiHost from "../utils/apiHost";
import {DataGrid} from '@mui/x-data-grid';
import MDButton from "../components/MDButton";
import NewInventoryItemsDialog from "../dialogs/NewInventoryItemsDialog";
import PrintDialog from "../dialogs/PrintDialog";
import loadInventory from "../utils/inventoryHelpers";
import InventoryType from "../utils/inventoryTypes";
import {useSnackbar} from "../useSnackbar";
import {useAuth} from "../useAuth";

function InventoryList({type, farm = null}) {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [inventory, setInventory] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [newDialogOpen, setNewDialogOpen] = useState(false);
    const [printDialogOpen, setPrintDialogOpen] = useState(false);
    const {showError, showSuccess} = useSnackbar();
    const { performLoginCheck } = useAuth();

    /**
     * Used for Breeding Cages
     *
     * @param params
     * @returns {*}
     */
    function getFollowerCount(params) {
        return params.row.data.followers.length;
    }

    const columns = [
        { field: 'active', headerName: 'Active', type: 'boolean', width: 150 },
        { field: 'sequenceNumber', headerName: 'Seq#', type: 'number', width: 40 },
        { field: 'state', headerName: 'State', width: 150 },
        { field: 'lastUpdate', headerName: 'Last Updated',
            type: 'dateTime', width: 250,
            valueGetter: ({ value }) => value && new Date(value)
        },
    ];

    if(type === InventoryType.BREEDING_CAGE) {
        columns.push({ field: 'data.followers', headerName: '#Followers',
            type: 'number', width: 80,
            valueGetter: getFollowerCount
        });
    }

    const handleAdd = () => {
        setNewDialogOpen(true);
    }

    const handlePrint = () => {
        setPrintDialogOpen(true);
    }

    useEffect(() => {
        if(!dataLoaded) {
            loadInventory(type.list, farm.id, setInventory, setDataLoaded, showError);
        }
    }, [dataLoaded, showError, farm.id, type.list]);

    useEffect(() => {
        setInventory([]);
        setSelectionModel([]);

        if(farm && type) {
            setDataLoaded(false);
        } 
    }, [farm, type])

    function onNewDialogClose() {
        setNewDialogOpen(false);
    }

    function onNewInventoryAdd(numberItems) {
        console.log(numberItems);

        fetch(`${apiHost()}/api/${type.list}?farmId=${farm.id}`, {
            method: 'POST',
            credentials: apiHost().length === 0 ? 'same-origin' : 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: "number=" + parseInt(numberItems)
        })
            .then(data => {
                if(data.status === 200) {
                    data.json().then(dta => {
                        if(dta.success) {
                            // trigger reload
                            setDataLoaded(false);
                            showSuccess("items added");
                        } else {
                            if(data.status === 401) {
                                performLoginCheck(null);
                            } else {
                                showError(dta.reason);
                            }
                        }
                    });
                } else {
                    showError("Request failed (" + data.status + ")");
                }

            })
            .catch(err => {
                console.log(err);
                showError("Request failed (Network Error?)");
            }).finally(() => {
                setNewDialogOpen(false);
        });

        setNewDialogOpen(false);
    }

    return (
        <MDBox mb={2} flex={2}>
            <NewInventoryItemsDialog handleClose={onNewDialogClose} inventoryTitle={type.titleOne}
                                     handleAdd={onNewInventoryAdd} open={newDialogOpen} />
            <PrintDialog inventoryType={type.common} inventoryIds={selectionModel}
                         handleClose={() => setPrintDialogOpen(false)} open={printDialogOpen} />

            <MDBox mb={2} display="flex" justifyContent={selectionModel.length > 0 ? "space-between" : "right"}>
                {
                    selectionModel.length > 0 &&
                    <MDButton variant="gradient" color="secondary" circular={true} onClick={handlePrint}>Create QR</MDButton>
                }

                <MDButton variant="gradient" color="primary" circular={true} onClick={handleAdd}>Add</MDButton>
            </MDBox>
            <DataGrid columns={columns} rows={inventory} autoHeight={true}
                      checkboxSelection={true}
                      onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                      }}
                      selectionModel={selectionModel}
            />
        </MDBox>
    );
}

export default InventoryList;


InventoryList.propTypes = {
    type: PropTypes.oneOf([InventoryType.BREEDING_CAGE, InventoryType.HATCHING_BOX]).isRequired,
    farm: PropTypes.object
};