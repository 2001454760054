import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";

import rgba from "assets/theme-dark/functions/rgba";

const { black, white, text, background } = colors;
const { borderWidth } = borders;

const dataGrid = {
  styleOverrides: {
    root: {
      color: white.main,
      backgroundColor: background.card,
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
    },
    row: {
      /*'& .MuiSvgIcon-root': {
        backgroundColor: background.white,
      }*/
    },
    cell: {
      '& .MuiSvgIcon-root': {
        color: `${white.main}`
      },
      '& .MuiBox-root .MuiIcon-root': {
        color: `${white.main}`
      },
      '& .MuiChip-root': {
        backgroundColor: background.default,
        color: white.main
      }
    },
    footerContainer: {
      '& .MuiTablePagination-root': {
        color: text.main
      }

    }
  },
};

export default dataGrid;
