import * as PropTypes from "prop-types";
import React from "react";
import {Algae, BlackSoldierFly, Mushroom} from "../assets/FarmIcons";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature";


function FarmTypeIcon({farmType}) {
    switch (farmType) {
        case 'BLACK_SOLDIER_FLY':
            return <BlackSoldierFly />;
        case 'MUSHROOM':
            return <Mushroom/>;
        case 'SPIRULINA':
            return <Algae />;
        default:
            return <EmojiNatureIcon/>;
    }
}

FarmTypeIcon.propTypes = {
    farmType: PropTypes.string.isRequired
};

export default FarmTypeIcon;