import colors from "../../base/colors";

const { white } = colors;

const loadingButton = {
  styleOverrides: {
    root: {
      color: white.main,
    }
  },
};

export default loadingButton;
