import React from 'react';
import {DotLottiePlayer} from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';
import LoadingAnimation from "assets/images/loading-logo.lottie"
import MDBox from "../components/MDBox";

const LoadingPage = ({height = "100vh", width = "100%", logoWidth = "35%"}) => {
    return (
        <MDBox display="flex" justifyContent="center" sx={{
            height: {height},
            width: {width}
        }}>
            <div style={{width: logoWidth}}>
                <DotLottiePlayer
                    src={LoadingAnimation}
                    autoplay
                    loop
                />
            </div>
        </MDBox>
);
};

export default LoadingPage;