function apiHost() {
	let api_url='';

	if(process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development'){
		//local api url
		api_url = 'https://localhost:18088';
	}

	return api_url;
}

export function wsHost() {
	let ws_url='wss://' + window.location.host + '/ws';

	if(process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development'){
		//local api url
		ws_url = 'wss://localhost:18088/ws';
	}

	return ws_url;
}

export default apiHost
