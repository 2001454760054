import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";

const { borderRadius } = borders;
const { background, light } = colors;

const cardHeader = {
  styleOverrides: {
    root: {
      backgroundColor: background.sidenav,
      color: light.main,
      borderTopLeftRadius: borderRadius.xl,
      borderTopRightRadius: borderRadius.xl
    },
  },
};

export default cardHeader;
