import React from "react";
import {BrowserRouter} from "react-router-dom";
import App from "App";
import {AuthProvider} from "./useAuth";
import {MaterialUIControllerProvider} from "context";
import {createRoot} from 'react-dom/client';
//import Bugsnag from '@bugsnag/js'
//import BugsnagPluginReact from '@bugsnag/plugin-react'
import {SnackbarProvider} from "./useSnackbar";

/*
Bugsnag.start({
    apiKey: 'adc853b836650fb8f164c1c23c59bcbf',
    plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)
*/

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    //<ErrorBoundary>
        <BrowserRouter>
            <MaterialUIControllerProvider>
                <AuthProvider>
                    <SnackbarProvider>
                        <App />
                    </SnackbarProvider>
                </AuthProvider>
            </MaterialUIControllerProvider>
        </BrowserRouter>
    //</ErrorBoundary>
);
