import {Fragment, useEffect, useState} from "react";

// react-router-dom components
import {NavLink, useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "components/Sidenav/SidenavCollapse";
import SidenavList from "components/Sidenav/SidenavList";
import SidenavItem from "components/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "components/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {setMiniSidenav, setTransparentSidenav, setWhiteSidenav, useMaterialUIController,} from "context";
import {useAuth} from "../../useAuth";
import {FormControl, InputLabel, ListItem, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {collapseItem} from "./styles/sidenavCollapse";
import theme from "../../assets/theme";

function Sidenav({ color = "info", brand = "", brandIcon, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[1];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  const [inventoryFarm, setInventoryFarm] = useState('');

  const { user, setSelectedFarm } = useAuth();

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const handleInventoryFarmChanged = (event) => {
    setInventoryFarm(event.target.value);
    setSelectedFarm(user.farms.find((s) => { return s.id === event.target.value }));
  }

  useEffect(() => {
    if(user.farms) {
      if (user.farms.length === 1) {
        setInventoryFarm(user.farms[0].id);
        setSelectedFarm(user.farms.find((s) => {
          return s.id === user.farms[0].id
        }));
      } else {
        if(inventoryFarm === '' && user.hasOwnProperty('primaryFarmId') && user.primaryFarmId != null) {
          setInventoryFarm(user.primaryFarmId);
            setSelectedFarm(user.farms.find((s) => {
            return s.id === user.primaryFarmId
          }));
        }
      }
    }
  }, [inventoryFarm, setSelectedFarm, user]);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, [collapseName, itemParentName]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, transparentSidenav, whiteSidenav]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route, roles, stages }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          if(!roles || user.roles.some(r=> roles.includes(r))) {
            if(!stages || (inventoryFarm.length > 0
                && (user.farms.find((s) => { return s.id === inventoryFarm }) || { stages: [] })
                    .stages.some(s => stages.includes(s)) )) {
              returnValue = (
                  <NavLink to={route} key={key}>
                    <SidenavCollapse
                        name={name}
                        icon={icon}
                        noCollapse={noCollapse}
                        active={key === collapseName}
                    >
                      {collapse ? renderCollapse(collapse) : null}
                    </SidenavCollapse>
                  </NavLink>
              );
            }
          }
        } else {
          if(!roles || user.roles.some(r=> roles.includes(r))) {
            returnValue = (
                <SidenavCollapse
                    key={key}
                    name={name}
                    icon={icon}
                    active={key === collapseName}
                    open={openCollapse === key}
                    onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
            );
          }
        }
      } else if (type === "title" ) {
          if(!roles || user.roles.some(r=> roles.includes(r))) {
            if(!miniSidenav) {
              returnValue = (
                  <Fragment key={'fragment-' + key}>
                    <MDTypography
                        key={key}
                        color={textColor}
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        pl={3}
                        mt={2}
                        mb={1}
                        ml={1}
                    >
                      {title}
                    </MDTypography>
                  </Fragment>
              );
            } else {
              // is mini
            }
        }
      } else if (type === "title-inventory" ) {
          if(!roles || user.roles.some(r=> roles.includes(r))) {
            if(!miniSidenav) {
              returnValue = (
                  <Fragment key={'fragment-' + key}>
                    <MDTypography
                        key={key}
                        color={textColor}
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        pl={3}
                        mt={2}
                        mb={1}
                        ml={1}
                    >
                      {title}
                    </MDTypography>
                    <ListItem key="inventory-li" component="li">
                      <MDBox
                          key="inventory-farm-box"
                          {...rest}
                          sx={(theme) =>
                              collapseItem(theme, {transparentSidenav, whiteSidenav, darkMode})
                          }
                      >
                        {user.farms.length === 1 ?
                            <MDTypography color={textColor} variant="h6">{user.farms[0].name}</MDTypography>
                            : <FormControl key="inventory-farm-formcontrol" fullWidth>
                              <InputLabel key="inventory-farm-select-label" sx={{color: theme.palette.white.main}}
                                          id="inventory-farm-select-label">{inventoryFarm === '' ? "Select..." : "Farm"}</InputLabel>
                              <Select
                                  key="inventory-farm"
                                  sx={{
                                    p: 1.5,
                                    ".MuiSelect-select": {
                                      color: theme.palette.white.main
                                    },
                                    ".MuiSelect-icon": {
                                      display: "block",
                                      color: theme.palette.white.main
                                    }
                                  }}
                                  labelId="inventory-farm-select-label"
                                  value={inventoryFarm}
                                  onChange={handleInventoryFarmChanged}
                                  label={inventoryFarm === '' ? "Select..." : "Farm"}

                              >
                                {
                                  user.farms.map((farm) => {
                                    return <MenuItem key={"mi-farm-" + farm.id} value={farm.id}>{farm.name}</MenuItem>
                                  })
                                }
                              </Select>
                            </FormControl>
                        }
                      </MDBox>
                    </ListItem>
                  </Fragment>
              );
            } else {
              // is mini
            }
          }
      } else if (type === "divider") {
        if(!roles || user.roles.some(r=> roles.includes(r))) {
          returnValue = (
              <Divider
                  key={key}
                  light={
                      (!darkMode && !whiteSidenav && !transparentSidenav) ||
                      (darkMode && !transparentSidenav && whiteSidenav)
                  }
              />
          );
        }
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>

        <MDBox component={NavLink} to="/u/dashboard" display="flex" flexDirection="column" alignItems="center">
          {(brand && !miniSidenav) && <MDBox component="img" src={brand} alt="Brand" width="12rem" />}
          {(brandIcon && miniSidenav) && <MDBox component="img" src={brandIcon} alt="Brand" height="36px" />}
          <MDBox
            width={"100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider key="nav-header-divider"
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}


// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
