import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import typography from "assets/theme/base/typography";
import {EcoWorld} from "../assets/FarmIcons";

function PublicFooter({ light = false }) {
    const { size } = typography;

    return (
        <MDBox position="absolute" width="100%" bottom={0} py={4}>
            <Container>
                <MDBox
                    width="100%"
                    display="flex"
                    flexDirection={{ xs: "column", lg: "row" }}
                    justifyContent="center"
                    alignItems="center"
                    px={1.5}
                >
                    <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexWrap="wrap"
                        color={light ? "white" : "text"}
                        fontSize={size.sm}
                    >
                        &copy; {new Date().getFullYear()}, made for&nbsp;
                        <MDBox fontSize={size.xl} color={light ? "white" : "dark"} mb={-0.5} mx={0.25}>
                            <EcoWorld/>
                        </MDBox>
                        &nbsp;by
                        <Link href="https://vileala.ch/" target="_blank">
                            <MDTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
                                &nbsp;Vileala GmbH&nbsp;
                            </MDTypography>
                        </Link>
                    </MDBox>
                </MDBox>
            </Container>
        </MDBox>
    );
}

// Typechecking props for the Footer
PublicFooter.propTypes = {
    light: PropTypes.bool,
};

export default PublicFooter;
