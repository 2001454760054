import {useState} from "react";

import {useNavigate} from "react-router-dom";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


// Images
import MDAlert from "../components/MDAlert";
import apiHost from "../utils/apiHost";
import {useAuth} from "../useAuth";
import {setDarkMode, useMaterialUIController} from "../context";

function Login() {
    let navigate = useNavigate();

    const { setUser, setNotifications, checkForNewUser } = useAuth();
    const [, dispatch] = useMaterialUIController();

    const [rememberMe, setRememberMe] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginFailed, setLoginFailed] = useState(false);

    const loginUser = async (credentials) => {
        const host = apiHost();

        return fetch(`${host}/api/auth/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `username=${credentials.user}&password=${credentials.pass}${credentials.rememberMe ? '&reme=on' : '' }`,
            credentials: host.length === 0 ? 'same-origin' : 'include'
        })
            .then(data => {
                if(data.status === 401) {
                    // login failed or account not enabled
                    return null;
                } else if(data.status === 403) {
                    // login succeeded, but email verification pending
                    // TODO: send user to a form to re-send verification email
                    return null;
                } else if(data.status === 200) {
                    return data.json();
                } else {
                    console.debug("Login failed. Status code: " + data.statusCode);
                    return null;
                }
            })
            .catch(err => {
                console.log(err);
                return null;
            })
    }

    const performLogin = async (e) => {
        e.preventDefault();
        setLoginFailed(false);

        await loginUser({
            user: username,
            pass: password,
            rememberMe: rememberMe
        }).then(async (user) => {

            if (user !== null) {
                document.cookie = "username=" + user.username + "; samesite=strict; path=/" + (apiHost().length === 0 ? "; secure" : "");
                console.debug("setNotifications of user object (performLogin) - " + user.notifications.length);
                checkForNewUser(user.notifications);
                setNotifications(user.notifications);
                delete user['notifications'];
                setUser(user);
                setDarkMode(dispatch, user.darkMode);
                navigate('/u/dashboard' );
            } else {
                setLoginFailed(true);
            }
        });
    }

    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    return (

        <Card>
            <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
            >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    Sign in
                </MDTypography>
                {/*
                <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                    <Grid item xs={2}>
                        <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                            <FacebookIcon color="inherit" />
                        </MDTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                            <GitHubIcon color="inherit" />
                        </MDTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                            <GoogleIcon color="inherit" />
                        </MDTypography>
                    </Grid>
                </Grid>
                */}
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
                { loginFailed &&
                    <MDAlert mb={2} color="error">Login failed</MDAlert>
                }
                <MDBox component="form" role="form">
                    <MDBox mb={2}>
                        <MDInput type="email" label="Email" fullWidth
                                 value={username}
                                 onChange={(e) => setUsername(e.target.value)}
                        />
                    </MDBox>
                    <MDBox mb={2}>
                        <MDInput type="password" label="Password" fullWidth
                                 value={password}
                                 onChange={(e) => setPassword(e.target.value)}
                                 onKeyDown={e => e.key === 'Enter' && performLogin(e)}
                        />
                    </MDBox>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                        <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                        <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            onClick={handleSetRememberMe}
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                            &nbsp;&nbsp;Remember me
                        </MDTypography>
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                        <MDButton variant="gradient" color="info" fullWidth
                                onClick={performLogin}
                                onKeyDown={e => e.key === 'Enter' && performLogin(e)}>
                                sign in
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default Login;