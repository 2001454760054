import colors from "assets/theme-dark/base/colors";
const { background } = colors;


const accordion = {
  styleOverrides: {
    root: {
      /*display: "flex",
      flexDirection: "column",
      position: "relative",
      minWidth: 0,
      wordWrap: "break-word",
      backgroundImage: "none",*/
      backgroundColor: background.card,
      /*backgroundClip: "border-box",
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
      borderRadius: borderRadius.xl,
      boxShadow: md,
      overflow: "visible",*/
    },

  },
};

export default accordion;
