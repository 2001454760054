import apiHost from "./apiHost";

const loadInventory = (type, farmId, setInventory, setDataLoaded, showError) => {

	fetch(`${apiHost()}/api/${type}?farmId=${farmId}`, {
		method: 'GET',
		credentials: apiHost().length === 0 ? 'same-origin' : 'include',
	})
		.then(data => {
			if(data.status === 200) {
				data.json().then(dta => {
					console.log(dta);
					setInventory(dta);
					setDataLoaded(true);
				})
			} else {
				setDataLoaded(true);
				showError("Request failed (" + data.status + ")");
			}
		})
		.catch(err => {
			console.log(err);
			showError && showError("Request failed (Network Error?)");
			// avoid loadInventory to be called infinitely
			setDataLoaded(true);
		});
}

export default loadInventory;
