import {useEffect, useRef, useState} from "react";

import {useLocation, useNavigate} from "react-router-dom";

import PropTypes from "prop-types";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";

import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";

import {
  navbar,
  navbarContainer,
  navbarDesktopMenu,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "components/Navbars/DashboardNavbar/styles";

import {setDarkMode, setMiniSidenav, setTransparentNavbar, useMaterialUIController,} from "context";
import {useAuth} from "../../../useAuth";

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MDAvatar from "../../MDAvatar";
import CustomMenuItem from "../../Items/CustomMenuItem";
import apiHost from "../../../utils/apiHost";

import {useSnackbar} from "../../../useSnackbar";
import {Alert, Stack, styled} from "@mui/material";
import MDTypography from "../../MDTypography";
import Switch from "@mui/material/Switch";

function DashboardNavbar({ absolute = false, light = false, isMini = false, }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openAccountMenu, setOpenAccountMenu] = useState(false);
  const [newNotification, setNewNotification] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { logout, user, notifications, performLoginCheck, selectedFarm } = useAuth();
  const {showError} = useSnackbar();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      console.debug("handleTransparentNavbar()");
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  //const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenAccountMenu = (event) => setOpenAccountMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseAccountMenu = () => setOpenAccountMenu(false);

  const handleDarkMode = () => {
    setDarkMode(dispatch, !darkMode);

    fetch(`${apiHost()}/api/user/save`, {
      method: 'PUT',
      credentials: apiHost().length === 0 ? 'same-origin' : 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        uid: user.uid,
        darkMode: !darkMode
      })
    })
        .then(data => {
          if(data.status === 200) {
            data.json().then(dta => {
              if(!dta.success) {
                console.error("failed to persist darkMode");
              }
            });
          } // ignore any error here
        })
        .catch(err => {
          console.log(err);
        });
  };

  let navigate = useNavigate();

  const editProfile = () => {
    setOpenAccountMenu(false);
    navigate('/u/profile');
  }

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const usePreviousValue = (val) => {
    const prev = usePrevious(val);
    return prev ? prev.length : 0;
  }

  const previousNotifications = usePreviousValue(notifications);

  const deleteNotification = (notificationId) => {
    console.debug("delete notification: " + notificationId);
    fetch(`${apiHost()}/api/notification/${notificationId}`, {
      method: 'DELETE',
      credentials: apiHost().length === 0 ? 'same-origin' : 'include'
    })
        .then(data => {
          if(data.status === 200) {
            data.json().then(dta => {
              // delete notification happens via websocket
              if(!dta.success) {
                showError(dta.reason + ": " + dta.message);
              }
            });
          } else {
            if(data.status === 401) {
              performLoginCheck(null);
            } else {
              showError("Request failed (" + data.status + ")");
            }
          }
        })
        .catch(err => {
          console.log(err);
          showError("Request failed (Network Error?)");
        });
  }

  // Render the notifications menu
  const PulsedNotification = styled("div")({
    '@keyframes pulse-animation': {
        '0%': {
          boxShadow: '0 0 0 0px rgba(229, 57, 53, 0.5)'
        },
        '100%': {
          boxShadow: '0 0 0 200px rgba(0, 0, 0, 0)'
        }
      },
    animation: "pulse-animation 2s normal",
    border: 'none',
    borderRadius: '10rem',
    backgroundColor: '#E53935',
    height: '20px',
    width: '20px',
    position: 'absolute',
    right: '-2px',
    top: '-5px',
  });

  const renderNotifications = () => (
      <>
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
        >
          { notifications.length > 0 ?
              notifications.map((notification) => (
                  /*HIGHEST
                  HIGH
                  MEDIUM
                  LOW*/
                  <NotificationItem notificationId={notification.id}
                                    created={notification.created}
                                    from={notification.from}
                                    severity={notification.severity}
                                    farm={(notification.farm && notification.farm !== selectedFarm.id) ?
                                        user.farms.filter(f => f.id === notification.farm)[0].name
                                        : null}
                                    message={notification.message}
                                    key={notification.id}
                                    onDelete={deleteNotification} />
              ))
              : <Alert sx={{alignItems: "center", minWidth: '500px',
                maxWidth: '500px'}} severity="success">No Notifications</Alert>
          }
        </Menu>
      </>

  );

  const renderAccountMenu = () => (
      <Menu
          anchorEl={openAccountMenu}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(openAccountMenu)}
          onClose={handleCloseAccountMenu}
          sx={{ mt: 2 }}
      >
        <CustomMenuItem icon={<ManageAccountsIcon />} title="Profile" onClick={editProfile} />
        <CustomMenuItem icon={<Icon>logout</Icon>} title="Logout"
                          onClick={logout}/>

      </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const timerNewNotificationRef = useRef(null);

  useEffect(() => {
    if(notifications.length > previousNotifications) {
      setNewNotification(true);
      timerNewNotificationRef.current = setTimeout(() => setNewNotification(false), 2500);
    }
  }, [notifications, previousNotifications])

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => {
      if(timerNewNotificationRef.current != null) {
        clearTimeout(timerNewNotificationRef.current);
      }
    };
  }, []);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <Stack display='inline-flex' direction="row" spacing={1} alignItems="center"
                     sx={navbarIconButton} mr={2}>
                <MDTypography variant="subtitle2" fontWeight="bold">Dark Mode</MDTypography>
                <Switch checked={darkMode} onChange={handleDarkMode} />
              </Stack>

              <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="account-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenAccountMenu}
              >
                <MDAvatar sx={iconsStyle} src={user.avatar} size="xs" shadow="sm" bgColor="dark" />
                {/*<Icon sx={iconsStyle}>account_circle</Icon>*/}
              </IconButton>
              {renderAccountMenu()}

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                { newNotification &&
                    <PulsedNotification />
                }

                <MDBadge badgeContent={notifications.length} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton>
              { selectedFarm && renderNotifications()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
