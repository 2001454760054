import colors from "assets/theme-dark/base/colors";

const { background, light } = colors;

const appBar = {
  defaultProps: {
    color: "transparent",
  },

  styleOverrides: {
    root: {
      boxShadow: "none",
      backgroundColor: background.sidenav,
      color: light.main
    },
  },
};

export default appBar;
