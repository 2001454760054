import {lazy, Suspense, useCallback, useEffect, useRef, useState} from "react";

import {Navigate, Route, Routes, useLocation} from "react-router-dom";

import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";

import themeDark from "assets/theme-dark";

import {useMaterialUIController} from "context";


// Images
import bgImage from "assets/images/bg-sign-in.jpeg";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ProtectedLayout from "./layouts/ProtectedLayout";
import PublicPageLayout from "./layouts/PublicPageLayout";
import {useAuth} from "./useAuth";
import {useSnackbar} from "./useSnackbar";
import MDBox from "./components/MDBox";
import * as PropTypes from "prop-types";
import InventoryList from "./pages/InventoryList";
import InventoryType from "./utils/inventoryTypes";
import apiHost, {wsHost} from "./utils/apiHost";
import {Client} from '@stomp/stompjs';
import LoadingPage from "./pages/LoadingPage";

const UserProfile = lazy(() => import('./pages/UserProfile'));
const Users = lazy(() => import('./pages/Users'));
const Devices = lazy(() => import('./pages/Devices'));
const Farms = lazy(() => import('./pages/Farms'));
const Settings = lazy(() => import('./pages/Settings'));
const ValuesOverview = lazy(() => import('./pages/ValuesOverview'));
const DeviceOverview = lazy(() => import('./pages/DeviceOverview'));
const GatewayTasks = lazy(() => import('./pages/GatewayTasks'));


function HatchingBoxes(props) {
  return (<MDBox>Hatching Boxes</MDBox>);
}

HatchingBoxes.propTypes = {
  farm: PropTypes.object
};

function HatchlingContainers(props) {
  return (<MDBox>Hatchling Containers</MDBox>);
}

HatchlingContainers.propTypes = {
  farm: PropTypes.object,
};

function Crates(props) {
  return (<MDBox>Crates</MDBox>);
}

Crates.propTypes = {
  farm: PropTypes.object
};
export default function App() {
  const { user, updateNotifications, performLoginCheck } = useAuth();
  const { renderSnackbar, showError } = useSnackbar();
  const [controller, dispatch] = useMaterialUIController();
  const [csrfTokenLoading, setCsrfTokenLoading] = useState(false);
  const [csrfToken, setCsrfToken] = useState(null);
  const [subscriptionsSet, setSubscriptionsSet] = useState(false);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [websocketPaused, setWebsocketPaused] = useState(false);

  const {
    direction,
    darkMode,
  } = controller;

  const { pathname } = useLocation();
  const { renderLoginModal, selectedFarm } = useAuth();

  const clientRef = useRef(null);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    console.debug(dispatch);
  }, [dispatch]);

  /*const logDebug = (msg) => {
    console.debug(msg);
  }*/

  const onConnected = useCallback(() => {
    console.debug("WS connected");
    setWebsocketConnected(true);
    setSubscriptionsSet(false);
    //clientRef.current.subscribe("/topic/data/63d511304f62c1610b9bb7f9", onData);
  }, []);


  function onDisconnected() {
    console.debug("WS disconnected")
    setWebsocketConnected(false);
    setSubscriptionsSet(false);
  }

  const unpauseWebsocket = () => {
    setCsrfToken(null);
    setWebsocketPaused(false);
  }

  const onError = useCallback((resp) => {
    console.debug("WS onError");
    if(resp.headers.message.endsWith("Access is denied")
        || resp.headers.message === "Failed to send message to ExecutorSubscribableChannel[clientInboundChannel]") {
      if(clientRef != null && clientRef.current != null && clientRef.current.active) {
        clientRef.current.deactivate();
        clientRef.current = null;
        setSubscriptionsSet(false);
        setWebsocketConnected(false);
        setWebsocketPaused(true);
        performLoginCheck(unpauseWebsocket);
      }
    }
  }, [performLoginCheck]);

  useEffect(() => {
    if(user && clientRef.current == null && csrfToken != null && !websocketPaused) {
      console.debug("setup ws for notifications");
      clientRef.current = new Client({
        brokerURL: wsHost(),
        connectHeaders: {
          'X-XSRF-TOKEN': document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1')
        },
        logRawCommunication: true,
        //debug: logDebug,
        reconnectDelay: 5000,
        heartbeatIncoming: 25000,
        heartbeatOutgoing: 25000,
        onConnect: onConnected,
        onDisconnect: onDisconnected,
        onStompError: onError
      });

      clientRef.current.activate();
    }
  }, [csrfToken, onConnected, onError, user, websocketPaused]);

  const onNotification = useCallback((msg) => {
    const notification = JSON.parse(msg.body);
    console.debug("incoming notification: " + JSON.stringify(notification));
    updateNotifications(notification);

    // TODO: what about updated notifications??
  }, [updateNotifications])

  useEffect(() => {
    if(!subscriptionsSet && websocketConnected && user != null) {
      console.debug("useEffect subscribe");
      clientRef.current.subscribe("/user/"+user.username+"/queue/notifications", onNotification);
      setSubscriptionsSet(true);
    } else if(subscriptionsSet && websocketConnected && user == null) {
      if(clientRef != null && clientRef.current != null && clientRef.current.active) {
        console.debug("useEffect deactivate");
        clientRef.current.deactivate();
        clientRef.current = null;
        setSubscriptionsSet(false);
        setWebsocketConnected(false);
        setCsrfToken(null);
      }
    }

  }, [onNotification, subscriptionsSet, user, websocketConnected]);

  const getCsrfToken = useCallback(() => {
    setCsrfTokenLoading(true);

    console.debug("useEffect get csrf");
    fetch(`${apiHost()}/csrf`, {
      method: 'GET',
      credentials: apiHost().length === 0 ? 'same-origin' : 'include',
    })
        .then(data => {
          if(data.status === 200) {
            data.text().then(dta => {
              if(dta.trim().length > 0) {
                setCsrfToken(dta);
              }
              setCsrfTokenLoading(false);
            })
          } else {
            if(data.status !== 401) {
              showError("Request failed (" + data.status + ")");
              setCsrfTokenLoading(false);
            }
          }
        })
        .catch(err => {
          console.log(err);
          showError("Request failed (Network Error?)");
          setCsrfTokenLoading(false);
        });
  }, [showError]);

  useEffect(() => {
    if(user != null && !csrfTokenLoading && !csrfToken) {
      getCsrfToken();
    }
  }, [csrfToken, csrfTokenLoading, getCsrfToken, user]);

  useEffect(() => {
    console.debug("useEffect main");
    // Clear the interval when the component unmounts
    return () => {
      if(clientRef != null && clientRef.current != null && clientRef.current.active) {
        clientRef.current.deactivate();
        clientRef.current = null;
      }
    }
  }, []);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {renderSnackbar()}
      {renderLoginModal()}
      <Suspense fallback={<LoadingPage logoWidth={"35%"} />}>
        <Routes>
          {/*getRoutes(routes)
          <Route path="/loading" element={<LoadingPage />} />*/}
          <Route path="/" element={<Navigate to="/u/dashboard" />}/>
          <Route path="/u" element={<ProtectedLayout />}>
            <Route path="*" element={<Dashboard />} />
            <Route path="profile" element={<UserProfile uid={user ? user.uid : null} />} />
            <Route path="users" element={<Users/>} />
            <Route path="users/add" element={<UserProfile />} />
            <Route path="user/:uid" element={<UserProfile />} />
            <Route path="devices">
              <Route index element={<Navigate to="/u/dashboard" />} />
              <Route path="mobiles" element={<Devices type="MOBILE" />} />
              <Route path="gateways" element={<Devices type="GATEWAY" />} />
              <Route path="field-devices" element={<Devices type="FIELD_DEVICE" />} />
              <Route path="proxy-devices" element={<Devices type="PROXY_DEVICE" />} />
            </Route>
            <Route path="farms" element={<Farms  />} />
            <Route path="settings" element={<Settings  />} />

            <Route path={InventoryType.BREEDING_CAGE.list}
                   element={<InventoryList type={InventoryType.BREEDING_CAGE} farm={selectedFarm} />} />
            <Route path={InventoryType.HATCHING_BOX.list}
                   element={<InventoryList type={InventoryType.HATCHING_BOX} farm={selectedFarm} />} />
            <Route path="hatchling-containers" element={<HatchlingContainers farm={selectedFarm} />} />
            <Route path="crates" element={<Crates farm={selectedFarm} />} />

            <Route path="status">
              <Route index element={<Navigate to="/u/dashboard" />} />
              <Route path="overview" element={<ValuesOverview />} />
              <Route path="device-overview" element={<DeviceOverview />} />
              <Route path="gateway-tasks" element={<GatewayTasks />} />
            </Route>
          </Route>

          <Route element={<PublicPageLayout background="default" image={bgImage} />}>
            <Route path="/u/login" element={<Login />} />
          </Route>

        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}
