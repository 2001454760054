import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";

import rgba from "assets/theme-dark/functions/rgba";

const { black, white, text, background } = colors;
const { borderWidth } = borders;

const collapse = {
  styleOverrides: {
    root: {
      color: white.main,
      backgroundColor: background.card,
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,

      '& .MuiListItemIcon-root': {
        color: `${white.main}`
      },
      '& .MuiListItemSecondaryAction-root': {
        '& .MuiSvgIcon-root': {
          color: `${text.main}`
        }
      }
    },
  },
};

export default collapse;
