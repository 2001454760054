import {forwardRef} from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";

// custom styles for the NotificationItem
import menuItem from "components/Items/NotificationItem/styles";
import Icon from "@mui/material/Icon";
import {Alert} from "@mui/material";
import MDTypography from "../../MDTypography";
import moment from "moment";
import MDBox from "../../MDBox";
import Avatar from "@mui/material/Avatar";
import {stringAvatar} from "../../../utils/stringUtils";
import {useAuth} from "../../../useAuth";

const NotificationItem = forwardRef(({ notificationId, created = null, from,
                                         severity, farm = null, message, onDelete = null, ...rest },
                                     ref) => {

    const { users } = useAuth();

    return (
        <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)} style={{padding: '0 0 0 0'}}>
            <Alert sx={{
                minWidth: '500px',
                maxWidth: '500px',
                alignItems: "center",
                '& .MuiAlert-action': {
                    paddingTop: 0
                },
                '& .MuiAlert-message': {
                    whiteSpace: 'break-spaces'
                }
            }}
                   severity={(severity === 'HIGHEST' || severity === 'HIGH') ? "error" : (severity === 'MEDIUM' ? "warning" : "info")}
                   onClose={onDelete ? () => onDelete(notificationId) : null}
                   iconMapping={{
                       info: <Icon fontSize="inherit" color="info">info_outlined</Icon>,
                       warning: <Icon fontSize="inherit" color="warning">report_problem</Icon>,
                       error: <Icon fontSize="inherit"
                                    color="error">{severity === 'HIGH' ? 'error_outline' : 'error'}</Icon>,
                   }}>
                {created &&
                    <MDTypography variant="caption" display="block"
                                  color="inherit">{moment(created).format('DD.MM.YYYY HH:mm')}</MDTypography>
                }
                {farm &&
                    <MDTypography variant="body2" color="inherit" fontWeight="bold">{farm}</MDTypography>
                }
                {(from && from !== 'SYSTEM') &&
                    <MDBox my={1} color="inherit" display="flex" gap={0.5} alignItems="center">
                        {
                            users[from] ?
                                (users[from].avatar ?
                                        <Avatar alt={users[from].displayName} src={users[from].avatar}
                                                sx={{width: 36, height: 36}}/>
                                        : <Avatar
                                            alt={users[from].displayName} {...stringAvatar(users[from].displayName, 36)} />
                                )
                                : <Avatar alt={from} src={undefined} sx={{width: 36, height: 36}}/>
                        }
                        <MDTypography variant="body2" color="inherit">{users[from].displayName}</MDTypography>
                    </MDBox>
                }
                <MDTypography variant="body2" color="inherit">{message}</MDTypography>
            </Alert>
        </MenuItem>
    );
});

NotificationItem.propTypes = {
    notificationId: PropTypes.string.isRequired,
    created: PropTypes.number,
    farm: PropTypes.string,
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['HIGHEST', 'HIGH', 'MEDIUM', 'LOW']),
    onDelete: PropTypes.func
};

export default NotificationItem;
